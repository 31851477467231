$blue: #0d6efd 
$blue-op: rgb(#0d6efd, .2)
$blue-900: #051e44 
$indigo: #6610f2 
$purple: #6f42c1 
$pink: #d63384 
$hotpink: #fd0d91
$red: #dc3545 
$orange: #fd7e14 
$yellow: #ffc107 
$green: #4EE39D 
$teal: #20c997 
$cyan: #0dcaf0 
$white: #fff 
$gray: #6c757d 
$gray-dark: #343a40 
$gray-100: #f8f9fa 
$gray-200: #e9ecef 
$gray-300: #dee2e6 
$gray-400: #ced4da 
$gray-500: #adb5bd 
$gray-600: #6c757d 
$gray-700: #495057 
$gray-800: #343a40 
$gray-900: #212529 
$primary: #0d6efd 
$secondary: #6c757d 
$success: #198754 
$info: #0dcaf0 
$warning: #ffc107 
$danger: #dc3545 
$light: #f8f9fa 
$dark: #212529 
$primary: #3ac4e5 
$main: #ff6854
$secondary: #343e61

