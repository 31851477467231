=backgroundImage($url)
    background: url($url)
    background-position: center
    background-repeat: no-repeat
    background-size: cover
=coverImage()
    height: 100%
    width: 100%
    object-fit: cover
=containImage()
    height: 100%
    width: 100%
    object-fit: contain
=ratio($width: null, $height: null, $ratio: 16/9)
    height: $height
    width: $width
    aspect-ratio: $ratio