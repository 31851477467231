@import '../../Utils/index'


.Plans
    .parent
        +relative
        background-image: linear-gradient(to bottom left, black, black, black, $blue, $blue)
        +bg($blue)
        .cards
            +p(4rem 2rem)
            +grid(3rem, $justify: center)
            +gtc(repeat(auto-fit, minmax(200px, 1fr)))
            .card
                +p(2rem 1.5rem)
                +radius(.4rem)
                +bg(white)
                +pointer
                +transition5
                +shadow(3px 3px 3px 3px black)
                min-width: 250px
                +columnFlex(1rem)
                .img
                    +size(2rem)
                .plan
                    +uppercase
                    +font(1.2rem, null, 800)
                .percentage
                    +font(1.5rem, null, 900)
                .btn
                    +flex(.5rem, center, center)
                    +container(50px, 100%, $blue)
                    +radius(.2rem)
                    +mt(auto)
                    +weight(500)
                    +transition5
                    +font(14px, white, 600)
                .wrapper
                    +columnFlex(1rem, center, flex-start)
                    .wrapper1
                        +flex(.3rem, null, flex-start)
                        +font(12px, null, 600)
                        +uppercase
                        svg
                            +size(14px)
                &:hover
                    +scale(1.05, 1.05)
                    +bg(transparent, white)
                    +shadow(3px -3px 3px 3px $gray-900)
                    
                &:hover .btn
                    +b(3px solid white)
                &:hover .wrapper
                    +weight(600)

        