@import '../../Utils/index'


.Footer
    .parent
        +grid($cgap: 3rem, $rgap: 5rem)
        +p(5rem 5rem 2rem)
        +width(100%)
        +gtc(1.3fr 3fr)
        +bg(white)
        @media (max-width: 1200px) 
            +p(4rem 2rem 2rem)
            +gtc(1fr)
        @media (max-width: 600px) 
            +p(4rem 1rem 2rem)
            +grid(1rem)
            
        .a
            +columnFlex(2rem)
            @media (max-width: 1200px) 
                grid-row: 2 / 3

            .header
            .wrapper
                +columnFlex(.5rem)
                &>:first-child
                    +font(14px, $blue, 600)
                .socials
                    &>*
                        +pointer
                    +flex(1rem)
            .cont
                +flex(.5rem, null, center)
                +radius(100vmax)
                background-image: linear-gradient(to bottom right, black, black, black, $blue, $blue)
                +p(.5rem)
                +wfit
                ::placeholder
                    +color(white)
                input
                    +container(35px, 10rem, transparent)
                    +pin(1rem)
                    +color(white)
                    +nooutline
                p
                    +bg(white)
                    +height(100%)
                    +centerFlex
                    +pin(1rem)
                    +size(13px)
                    +radius(100vmax)
        
        .allb
            +grid(2rem)
            +gtc(repeat(auto-fit, minmax(220px, 1fr)))
            .b
                +columnFlex(1rem)
                .header
                    +font(15px, nul, 600)
                ul
                    +noList
                    +font(14px, nul, 500)
                    +columnFlex(1rem)
            
            
