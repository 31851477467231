@import '../../Utils/index'


.Card
    .parent
        background-image: linear-gradient(to top right, black, black, black, $blue, $blue)
        +transition5
        +p(5rem)
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 600px) 
            +pin(1rem)
        .cards
            +transition5
            +grid(2rem)
            +gtc(repeat(auto-fit, minmax(300px, 1fr)))
            .card
                +p(2rem 1rem)
                +pointer
                +flex(1rem, flex-start, flex-start)
                +shadow(3px 3px 5px 2px $gray-900)
                +transition5
                +radius(.5rem)
                +bg(whitesmoke)
                &>:nth-child(1)
                    +size(50px)
                    +pb(1rem)
                    +color($blue)
                    +transition5
                .wrapper
                    +columnFlex(.5rem)
                    +color($blue)
                    &>:nth-child(1)
                        +font(1rem, black, 600)
                        +transition5
                    &>:nth-child(2)
                        +transition5
                        +font(.8rem, grey, 400)
                    &>:nth-child(3)
                        +size(12px)
                        +flex(.5rem, flex-start, center)
                        .right
                            +transition(2000ms)
                &:hover
                    +scale(1.1, 1.1)
                    +bg(white)
                    +radius(20%)
                    @media (max-width: 1000px) 
                        +scale(1, 1)
                &:hover > svg
                    +ml(2rem)
                &:hover > .wrapper > .title
                    +color($blue)
                &:hover > .wrapper > .sub
                    +color(black)
                &:hover > .wrapper > .cont > .right
                    +ml(4rem)
                    +color(red)
                    @media (max-width: 1000px) 
                        +ml(0)
                    