@import '../../Utils/index'


.Testimonial
    .parent
        background-image: linear-gradient(to bottom left, black, black, black, $blue, $blue)
        +p(5rem)
        +color(white)
        @media (max-width: 1200px) 
            +pin(2rem)
        @media (max-width: 600px) 
            +pin(1rem)
        .cards
            +grid(2rem)
            +gtc(repeat(auto-fit, minmax(300px, 1fr)))
            .card
                +p(1rem 1.5rem)
                +pointer
                +shadow(3px 3px 5px 2px $gray-900)
                +columnFlex(1.5rem)
                +transition5
                +radius(.5rem)
                .text
                    +font(14px, lightgrey)
                .wrapper
                    +flex(1rem, space-between, center)
                    .cont
                        +flex(1rem, null, center)
                        .circle
                            +circle(60px)
                            +hidden
                            img
                                +coverImage
                        .cover
                            +columnFlex(.1rem)
                            .name
                                +font(14px, white, 600)
                            .post
                                +font(14px, lightgrey, 500)
                                
                        .name
                    svg
                        +size(2.5rem)
                &:hover
                    +translateY(-1rem)
                    +bg(black)
                    +shadow(3px -3px 5px 3px $gray-800)
