@import '../../Utils/index'


.Main
    .parent
        background-image: linear-gradient(to bottom right, black, black, black, $blue, $blue)
        +color(white)
        .main
            +grid()
            +p(5rem)
            +gtc(1fr 1fr)
            @media (max-width: 1200px) 
                +grid(3rem)
                +p(4rem 2rem 1rem)
                +gtc(1fr)
            @media (max-width: 600px) 
                +p(4rem 1rem 1rem)
            .left
                +columnFlex(2rem)
                &>:nth-child(1)
                    +font(1rem, $blue, 600)
                &>:nth-child(2)
                    +maxWidth(35rem)
                    +font(3.5rem, null, 600)
                    @media (max-width: 750px) 
                        +font(2.7rem, null, 600)
                        +spacing(null, null, 4rem)
                        +maxWidth(30rem)
                        span
                            +bb(none)
                    span
                        +bb(5px solid $blue)
                        +color($blue)
                        +pb(.2rem)
                       
                &>:nth-child(3)
                    +maxWidth(30rem)
                    +font(18px, grey)
                    @media (max-width: 750px) 
                        +font(1rem, grey)
                        +spacing(null, null, 1.5rem)

                &>:nth-child(4)
                    +flex(1rem)
                    &>*
                        +p(.7rem 2rem)
                        +pointer
                        +font(14px, null, 600)
                        @media (max-width: 750px) 
                            +font(12px, null, 600)
                            +p(.7rem 1.5rem)

                    &>:nth-child(1)
                        +radius(100vmax, 2px solid $blue)
                        +transition5
                        +bg($blue, white)
                        &:hover
                            +shadow($blue 0px 3px 8px)
                    &>:nth-child(2)
                        +transition5
                        +bg(transparent, $blue)
                        +radius(100vmax, 2px solid $blue)
                        &:hover
                            +bg(transparent, white)
                            +radius(100vmax, 2px solid white)
            
            
            
            
            
            
            
            
            
            
            .right
                +relative
                .chain
                    +coverImage


            
               