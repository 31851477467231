=size($size)
    font-size: $size
=color($color)
    color: $color
=weight($weight)
    font-weight: $weight

=font($size, $color, $weight: null)
    font-size: $size
    color: $color
    font-weight: $weight
=style($style: null)
    font-style: $style
=italic
    font-style: italic
=lineHeight($line)
    line-height: $line
=spaceLetters($letter)
    letter-spacing: $letter
=spaceWords($word)
    word-spacing: $word
=spacing($letter: null, $word: null, $line: null)
    letter-spacing: $letter
    word-spacing: $word
    line-height: $line

=startText
    text-align: start
=centerText
    text-align: center
=endText
    text-align: end
=underline()
    text-decoration: underline
=noDecoration()
    text-decoration: none
=noList()
    list-style: none
=uppercase
    text-transform: uppercase
=lowercase
    text-transform: lowercase
=camelcase
    text-transform: capitalize